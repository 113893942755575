import React from "react";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel as ReactCarousel} from "react-responsive-carousel";

import "./index.css";
import img from "./img/asd11.jpg";

const Carousel = () => {
    const [banners, setBanners] = React.useState([]);

    React.useEffect(() => {
        axios
            .get("https://api.daralliance.me/api/banners")
            .then(({ data: res }) => {
                setBanners(res.data);
            });
    }, []);

    return (
        <div className="carousel-section">
            <div className="carousel-section__container">
                <ReactCarousel
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    autoPlay
                    swipeable
                    emulateTouch
                    infiniteLoop
                >
                    {
	                    !banners || banners.length == 0 ?
                            <div>
                                <img src={img} alt="" className="carousel-image"/>
                            </div>
                            :
                        banners?.map((banner) => (
                            <div
                                key={banner.id}
                                onClick={() => window.open(banner.link)}>
                                <img src={banner.banner.data.url} alt="" className="carousel-image"/>
                            </div>
                        ))
                    }

                </ReactCarousel>
            </div>
        </div>
    );
};

export default Carousel;
