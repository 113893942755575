import ScrollToTop from "hooks/ScrollToTop";
import {FormattedMessage} from "react-intl";
import Event from "containers/Events/Event/Event";
import React from "react";
import axios from "axios";
import Study from "containers/Studies/Study/Study";

const Studies = () => {

  const [studies, setStudies] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://api.daralliance.me/api/studies")
      .then(({ data: res }) => {
        setStudies(res.data);
      });
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="events page">
        <div className="page__container">
          <div className="page__wrapper">
            <div className="page__title">
              <FormattedMessage id="Studies.Title" />
            </div>
            <div className="events__list">
              {studies?.map((study) => (
                <Study data={study} key={study.id}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Studies;
