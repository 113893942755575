import React from "react";
import { FormattedMessage } from "react-intl";

import "./index.css";
import academyQR from "./img/dar_academy_qr.png";
import appstore from "./img/appstore.png";
import googleStore from "./img/google.png";
import out from "./img/Out.svg";
import phone from "./img/004.png";

const Application = () => {
  return (
    <section id="application" className="application">
      <div className="application__container">
        <div className="application__row">
          <div className="application__col">
            <div className="application__caption">
              <FormattedMessage id="Application.Caption" />
            </div>
            <div className="application__title">
              <FormattedMessage id="Application.Title" />
            </div>
            <div className="application__desc">
              <FormattedMessage id="Application.Desc" />
            </div>
            <div className="application__info">
              <div className="application__qr">
                <img src={academyQR} alt="" />
              </div>
              <div className="application__links">
                <div className="application__stores">
                  <a href="https://apps.apple.com/us/app/dar-academy/id1358484666" target="_blank">
                    <img src={appstore} alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.darapp" target="_blank">
                    <img src={googleStore} alt="" />
                  </a>
                </div>
                <a className={"linked-button random-nutrition__button"}
                    href={"https://daralliancehcp.org"}
                    target="_blank">
                  <FormattedMessage id="Application.Button" />
                  <img src={out} alt="" />
                </a>
              </div>
              <div className="application__clearfix"></div>
            </div>
          </div>
          <div className="application__col">
            <img src={phone} alt="" className="application__phone" />
          </div>
          <div className="application__clearfix"></div>
        </div>
      </div>
    </section>
  );
};

export default Application;
