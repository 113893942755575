import React from "react";
import ReactMarkdown from 'react-markdown'
import axios from "axios";
import "./AboutUs.css";
import { LANG } from "constants/constants";
import phoneImg from "containers/Home/SaFa/img/006.png";
import logo from "./img/dar_logo_text_dark.svg";


const AboutUs = () => {
  const [aboutUsEn, setAboutUsEn] = React.useState('');
  const [aboutUsFr, setAboutUsFr] = React.useState('');
  const [aboutUsAr, setAboutUsAr] = React.useState('');

  const lang = localStorage.getItem("lang");

  const getLocalizeKey = !lang || lang === "en" ? LANG.DEFAULT : lang;

  const getLang = getLocalizeKey => {
        switch (getLocalizeKey) {
            case LANG.DEFAULT:
                return aboutUsEn
            case LANG.FR:
                return aboutUsFr
            case LANG.AR:
                return aboutUsAr
            default:
                return aboutUsEn
        }
    }


    React.useEffect(() => {
    axios
        .get("https://api.daralliance.me/api/about-us")
        .then(({ data: res }) => {
          setAboutUsEn(res.data.content.default);
          setAboutUsFr(res.data.content.fr);
          setAboutUsAr(res.data.content.ar);
        });
  }, []);

  return (
    <section id="aboutus" className="aboutus">
        <div className="aboutus__container">
            <div className="aboutus-row">
                <div className="aboutus-col">
                    {getLocalizeKey && <div dir="auto"><ReactMarkdown children={getLang(getLocalizeKey)} /></div>}
                </div>
                <div className="aboutus-col-right">
                    <img className="about-us-img" src={logo} alt="" />
                </div>
            </div>
        </div>
    </section>
  );
};

export default AboutUs;
