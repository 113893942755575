import React from "react";
import { FormattedMessage } from "react-intl";
import ar from "./img/ar.png";
import fr from "./img/fr.png";
import en from "./img/en.png";
import arrow from "./img/arrow.svg";

import "./index.css";
import useOutsideClick from "hooks/outsideClick";

const OPTIONS = [
  {
    value: "en",
    label: (
      <>
        <img className="lang-switcher__flag" src={en} alt="" />
        <FormattedMessage id="Header.Lang.En" />
      </>
    ),
  },
  {
    value: "ar",
    label: (
      <>
        <img src={ar} className="lang-switcher__flag" alt="" />
        <FormattedMessage id="Header.Lang.Ar" />
      </>
    ),
  },
  {
    value: "fr",
    label: (
      <>
        <img src={fr} className="lang-switcher__flag" alt="" />
        <FormattedMessage id="Header.Lang.Fr" />
      </>
    ),
  },
];

const LangSwitcher = () => {
  const ref = React.useRef();
  const [activeClass, setActiveClass] = React.useState("");

  useOutsideClick(ref, () => {
    setActiveClass("");
  });

  const handleClick = (value) => {
    localStorage.setItem("lang", value);
    document.location.reload();
  };

  const lang = localStorage.getItem("lang") || "en";

  const toggleClass = () => {
    setActiveClass(!activeClass ? "active" : "");
  };

  return (
    <div className="lang-switcher" ref={ref}>
      <div
        className={`lang-switcher__selected-item ${activeClass}`}
        onClick={toggleClass}
      >
        {OPTIONS.find((option) => option.value === lang)?.label}
        <img className="lang-switcher__arrow" src={arrow} alt="" />
      </div>
      <div className={`lang-switcher__options ${activeClass}`}>
        {OPTIONS.filter((option) => option.value !== lang).map(
          (filteredOption) => (
            <div
              key={filteredOption.value}
              className="lang-switcher__option"
              onClick={() => handleClick(filteredOption.value)}
            >
              {filteredOption.label}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LangSwitcher;
