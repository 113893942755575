import React from "react";
import { FormattedMessage } from "react-intl";

import "./index.css";
import LinkButton from "components/Button/LinkButton";
import { urlLocations } from "routes/urlLocations";

const GuidelinesSection = () => {
  return (
    <section id="guidelines" className="guidelines-section">
      <div className="guidelines-section__container">
        <div>
          <div className="guidelines-section__col">
            <div className="guidelines-section__title">
              <FormattedMessage id="GuidelinesSection.Title" />
            </div>
            <div className="guidelines-section__desc">
              <FormattedMessage id="GuidelinesSection.Description" />
            </div>
            <LinkButton
              className="guidelines-section__button"
              to={urlLocations.guidelines}
            >
              <FormattedMessage id={"GuidelinesSection.Button"} />
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GuidelinesSection;
