import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { NAVIGATION } from "constants/constants";
import logo from "./img/dar_logo_colors.svg";
import "./index.css";
import { urlLocations } from "routes/urlLocations";
import LangSwitcher from "components/LangSwitcher/LangSwitcher";

const Header = () => {
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logo">
          <Link to={urlLocations.home}>
            <img src={logo} alt="logo" width={100}/>
          </Link>
        </div>
        <nav className="header__nav">
          <ul>
            {NAVIGATION.map((item) => (
              <li key={item.title}>
                <a href={item.to || ""}>
                  <FormattedMessage id={`Navigation.${item.title}`} />
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <LangSwitcher />
      </div>
    </header>
  );
};

export default Header;
