import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MainLayout from "../layouts/Main";
import Home from "../containers/Home/Home";
import Guidelines from "../containers/Guidelines/Guidelines";
import Events from "containers/Events/Events";
import { urlLocations } from "./urlLocations";
import Studies from "containers/Studies/Studies";

export default () => {
  return (
    <Router>
      <MainLayout>
        <Switch>
          <Route path={urlLocations.guidelines}>
            <Guidelines />
          </Route>
          <Route path={urlLocations.events}>
            <Events />
          </Route>
          <Route path={urlLocations.studies}>
            <Studies />
          </Route>
          <Route path={urlLocations.home}>
            <Home />
          </Route>
        </Switch>
      </MainLayout>
    </Router>
  );
};
