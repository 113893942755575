import React from "react";

import Carousel from "containers/Home/Carousel/Carousel";
import GuidelinesSection from "./GuidelinesSection/GuidelinesSection";
import RandomNutrition from "./RandomNutrition/RandomNutrition";
import Application from "./Application/Application";
import Countries from "./Countries/Countries";
import SaFa from "./SaFa/SaFa";
import AboutUs from "containers/Home/AboutUs/AboutUs";

const Home = () => {
  return (
    <>
      <Carousel />
      <GuidelinesSection />
      <RandomNutrition />
      <Application />
      <SaFa />
        <AboutUs />
        <Countries />
    </>
  );
};

export default Home;
