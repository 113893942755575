import React from "react";

import "./index.css";
import { FormattedMessage } from "react-intl";
import flag1 from "./img/image 2.png";
import flag2 from "./img/image 3.png";
import flag3 from "./img/image 4.png";
import flag4 from "./img/image 5.png";
import flag5 from "./img/image 6.png";
import flag7 from "./img/image 8.png";
import flag8 from "./img/image 9.png";
import flag9 from "./img/image 11.png";
import flag10 from "./img/image 12.png";
import flag11 from "./img/image 13.png";
import flag12 from "./img/image 14.png";
import flagEgypt from "./img/image 7.png";

const COUNTRIES = [
  {
    name: "Countries.Algeria",
    flag: flag1,
  },
  {
    name: "Countries.Belgium",
    flag: flag9,
  },
  {
    name: "Countries.Sudan",
    flag: flag10,
  },
  {
    name: "Countries.Bahrain",
    flag: flag7,
  },
  {
    name: "Countries.GreatBritain",
    flag: flag8,
  },
  {
    name: "Countries.SaudiArabia",
    flag: flag4,
  },
  {
    name: "Countries.Libya",
    flag: flag5,
  },
  {
    name: "Countries.France",
    flag: flag2,
  },
  {
    name: "Countries.Senegal",
    flag: flag3,
  },
  {
    name: "Countries.Egypt",
    flag: flagEgypt,
  },
  {
    name: "Countries.Pakistan",
    flag: flag12,
  },
  {
    name: "Countries.UAE",
    flag: flag11,
  },
];

const Countries = () => {
  return (
    <section id="countries" className="countries">
      <div className="countries__wrapper">
        <div className="countries__container">
          <div>
            <div className="countries__col">
              <div className="countries__title">
                <FormattedMessage id="Countries.Title" />
              </div>
              <div className="countries__list">
                {COUNTRIES.map((country) => (
                  <div className="countries__item" key={country.name}>
                    <img src={country.flag} alt="" />
                    <span>
                      <FormattedMessage id={country.name} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="countries__clearfix" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countries;
