import React from "react";
import { Link } from "react-router-dom";

import "./index.css";

const LinkButton = ({ children, to, className }) => {
  return (
    <Link className={`linked-button ${className || ""}`} to={to}>
      {children}
    </Link>
  );
};

export default LinkButton;
