import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";

import "./main.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes/routes";
import arDictionary from "./locales/ar";
import enDictionary from "./locales/en";
import frDictionary from "./locales/fr";

const APPDictionary = {
  ar: arDictionary,
  fr: frDictionary,
  en: enDictionary,
};

let lang = localStorage.getItem("lang");

if (!lang) {
  localStorage.setItem("lang", "en");
  lang = localStorage.getItem("lang");
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={lang} messages={APPDictionary[lang]}>
      <Routes />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
