import React from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import "./Events.css";
import ScrollToTop from "hooks/ScrollToTop";
import Event from "containers/Events/Event/Event";

const Events = () => {

    const [events, setEvents] = React.useState([]);
    
    React.useEffect(() => {
        axios
            .get("https://api.daralliance.me/api/events")
            .then(({ data: res }) => {
                setEvents(res.data);
            });
    }, []);

    return (
    <>
        <ScrollToTop />
        <div className="events page">
            <div className="page__container">
                <div className="page__wrapper">
                    <div className="page__title">
                        <FormattedMessage id="Events.Title" />
                    </div>
                    <div className="events__list">
                        {events?.map((event) => (
                            <Event data={event} key={event.id}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Events;
