import React from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";

import ScrollToTop from "hooks/ScrollToTop";
import "../../layouts/pages.css";
import "./index.css";

const Guidelines = () => {
  const [data, setData] = React.useState([]);

  const lang = localStorage.getItem("lang");

  const getLocalizeKey = !lang || lang === "en" ? "default" : lang;

  React.useEffect(() => {
    axios
      .get(" https://api.darallianceapps.com/guidelines")
      .then(({ data: res }) => {
        setData(res);
      });
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="guidelines page">
        <div className="page__container">
          <div className="page__wrapper">
            <div className="page__title">
              <FormattedMessage id="Guidelines.Title" />
            </div>
            <div className="guidelines__list">
              {data?.map((guideline) => (
                <a
                  rel="noreferrer"
                  key={guideline.id}
                  href={guideline.file.url}
                  target="_blank"
                  className="guidelines__item"
                >
                  <img src={guideline.thumbnail.url} alt="" />
                  {guideline.title[getLocalizeKey]}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guidelines;
