import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  home: "/",
  guidelines: "/guidelines",
  events: "/events",
  studies: "/studies",
};
