import React from "react";
import { FormattedMessage } from "react-intl";

import "./index.css";
import logo from "./img/dar_logo_text_dark.svg";
import sanofi from "./img/sanofi-new.png";
import { NAVIGATION } from "constants/constants";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top-section">
          <div className="footer__logo">
            <img src={logo} alt="" width={80}/>
          </div>
          <nav className="footer__navigation">
            <ul>
              {NAVIGATION.map((item) => (
                <li key={item.title}>
                  <a href={item.to || ""}>
                    <FormattedMessage id={`Navigation.${item.title}`} />
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="footer__clearfix" />
        </div>
        <div className="footer__bottom-section">
          <div className="footer__copyright">
            <FormattedMessage id="CopyRight" />
          </div>
          <div className="footer__sanofi">
            <img src={sanofi} alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
