import React from "react";
import { FormattedMessage } from "react-intl";

import phoneImg from "./img/006.png";
import appStore from "./img/appstore Button.png";
import googleStore from "./img/google Button.png";

import "./index.css";

const SaFa = () => {
  return (
    <section id="safa" className="safa">
      <div className="safa__container">
        <div>
          <div className="safa__col">
            <img className="safa__phone" src={phoneImg} alt="" />
          </div>
          <div className="safa__col">
            <div className="safa__info">
              <div className="safa__caption">
                <FormattedMessage id="SaFa.Caption" />
              </div>
              <div className="safa__title">
                <FormattedMessage id="SaFa.Title" />
              </div>
              <div className="safa__desc">
                <FormattedMessage id="SaFa.Desc" />
              </div>
              <div className="safa__stores">
                <a href="https://apps.apple.com/us/app/dar-safa/id1384878937" target="_blank">
                  <img src={appStore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.dar_safa.dar_safa" target="_blank">
                  <img src={googleStore} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="safa__clearfix" />
        </div>
      </div>
    </section>
  );
};

export default SaFa;
