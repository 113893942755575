export default {
  "Navigation.Guidelines": "Guidelines",
  "Navigation.AccessToPlan": "Ramadan Nutrition Plan",
  "Navigation.DARAcademy": "DAR Academy",
  "Navigation.DARSafa": "DAR Safa",
  "Navigation.DARAboutUs": "About Us",
  "Navigation.Events": "Events",

  "Header.Lang.En": "En",
  "Header.Lang.Fr": "Fr",
  "Header.Lang.Ar": "Ar",

  "GuidelinesSection.Title": "Diabetes and Ramadan Practical Guidelines",
  "GuidelinesSection.Description":
    "International Diabetes Federation (IDF), in collaboration with the Diabetes  and Ramadan (DAR) International Alliance",
  "GuidelinesSection.Button": "Guidelines",

  "RandomNutrition.Title": "You can find all the most popular Meals",
  "RandomNutrition.Desc":
    "DAR International Alliance would like to work with all those involved " +
    "with the management of diabetes during Ramadan fasting to make it a safe and " +
    "enjoyable month for those who observe it..",
  "RandomNutrition.Button": "Ramadan Nutrition Plan",

  "Application.Caption": "FREE APPLICATION",
  "Application.Title": "DAR Academy",
  "Application.Desc":
    "For health care practitioners, access updated lectures and information and download resources relevant to your practice",
  "Application.Button": "Visit Platform",

  "Countries.Title": "Countries where DAR Alliance is active:",
  "Countries.Algeria": "Algeria",
  "Countries.Belgium": "Belgium",
  "Countries.Sudan": "Sudan",
  "Countries.Bahrain": "Bahrain",
  "Countries.GreatBritain": "Great Britain",
  "Countries.SaudiArabia": "Saudi Arabia",
  "Countries.Libya": "Libya",
  "Countries.France": "France",
  "Countries.Senegal": "Senegal",
  "Countries.Egypt": "Egypt",
  "Countries.Pakistan": "Pakistan",
  "Countries.UAE": "UAE",

  "SaFa.Caption": "FREE APPLICATION",
  "SaFa.Title": "DAR SaFa",
  "SaFa.Desc": "Download the application and manage Diabetes in Ramadan",
  CopyRight:
    "The preparation of this app was made possible through an unrestricted education grant provided by Sanofi Gulf Countries\n" +
    "© 2020 DAR alliance. All rights reserved",

  "Guidelines.Title": "Guidelines",
  "Events.Title": "Events",
  "Events.MoreInfo": "More info",
  "Events.Date": "Date of event",

  "Navigation.Studies": "Studies",
  "Studies.Title": "Studies",
  "Study.ReadStudy": "Read Study"
};
