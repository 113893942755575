import React from "react";
import { FormattedMessage } from "react-intl";
import "./Study.css";
import img from '../../../assets/img/img0.jpg';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/ar';

const Study = (data) => {

    const { material, titleLocalized, titleDefault, banner } = data.data;

    const lang = localStorage.getItem("lang");

    const getLocalizeKey = !lang || lang === "en" ? "default" : lang;

    moment.locale(lang);

    console.log(material.data.url)

    return (
    <>
        <div className="event">
            <div  className="study-colleft">
                <img src={ banner?.data.url} alt={banner.data.caption} />
            </div>
            <div  className="event-colright">
                <h3>{titleLocalized[getLocalizeKey] ? titleLocalized[getLocalizeKey] : titleDefault  }</h3>
                {/*<p className="event-date"> <strong><FormattedMessage id="Events.Date" />: </strong><span> {moment(from).format('Do MMMM YYYY')  } </span> - { to && <span>{moment(to).format('Do MMMM YYYY')}</span> } </p>*/}
                {/*<p>{ descriptionLocalized[getLocalizeKey] }</p>*/}
                <a href={material.data.url} target="_blank" className="moreinfo-link" ><FormattedMessage id="Study.ReadStudy" /></a>
            </div>
        </div>
    </>
  );
};

export default Study;
