import React from "react";
import { FormattedMessage } from "react-intl";

import LinkButton from "components/Button/LinkButton";
import "./index.css";
import food from "./img/002.jpg";
import arrow from "./img/Arrow.svg";

const RandomNutrition = () => {
  return (
    <section id="random-nutrition" className="random-nutrition">
      <div className="random-nutrition__container">
        <div className="random-nutrition__row">
          <div className="random-nutrition__col">
            <img className="random-nutrition__food" src={food} alt="" />
          </div>
          <div className="random-nutrition__col">
            <div className="random-nutrition__title">
              <FormattedMessage id="RandomNutrition.Title" />
            </div>
            <div className="random-nutrition__desc">
              <FormattedMessage id="RandomNutrition.Desc" />
            </div>
            <a
              className={"linked-button random-nutrition__button"}
              href={"https://www.daralliance.org/daralliance/map/"}
              target="_blank"
            >
              <FormattedMessage id="RandomNutrition.Button" />{" "}
              <img src={arrow} alt="" />
            </a>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </section>
  );
};

export default RandomNutrition;
