import React from "react";
import Header from "../components/Header/Header";
import Footer from "components/Footer/Footer";

import "./index.css";

const Main = ({ children }) => {
  return (
    <>
      <main className="main-layout">
        <Header />
        <div className="main-layout__content">{children}</div>
        <Footer />
      </main>
    </>
  );
};

export default Main;
