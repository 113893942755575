export const NAVIGATION = [
  {
    title: "Guidelines",
    to: "/#guidelines",
  },
  {
    title: "AccessToPlan",
    to: "/#random-nutrition",
  },
  {
    title: "DARAcademy",
    to: "/#application",
  },
  {
    title: "DARSafa",
    to: "/#safa",
  },
  {
    title: "Events",
    to: "/events",
  },
  {
    title: "Studies",
    to: "/studies",
  },
  {
    title: "DARAboutUs",
    to: "/#aboutus",
  },
];

export const LANG = {
    DEFAULT: "default",
    FR: "fr",
    AR: "ar"
};
