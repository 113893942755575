export default {

    "Navigation.Guidelines": "الإرشادات",
    "Navigation.AccessToPlan": "خطة التغذية الرمضانية",
    "Navigation.DARAcademy": "دار اكاديمي",
    "Navigation.DARSafa": "دار صفا",
    "Navigation.DARAboutUs": "معلومات عنا",
    "Navigation.Events": "الأحداث",

    "Header.Lang.En": "En",
    "Header.Lang.Fr": "Fr",
    "Header.Lang.Ar": "Ar",

    "GuidelinesSection.Title": "مرض السكري ورمضان الارشادات العملية",
    "GuidelinesSection.Description":
        "الاتحاد الدولي للسكري (IDF) ، بالتعاون مع التحالف الدولي لمرض السكري ورمضان (DAR)",
    "GuidelinesSection.Button": "الإرشادات",

    "RandomNutrition.Title": "يمكنك العثور على جميع الأنواع الأكثر شعبية",
    "RandomNutrition.Desc":
        "يود دار اليانز الدولي للعمل مع جميع المشاركين في التحكم في مرض السكري خلال صيام رمضان لجعله شهرًا آمنًا وممتعًا لأولئك الذين يراقبونه.",
    "RandomNutrition.Button": "خطة التغذية الرمضانية",

    "Application.Caption": "تطبيق مجاني",
    "Application.Title": "دار اكاديمي",
    "Application.Desc":
        "لممارسي الرعاية الصحية ، تمكن من الوصول إلى المحاضرات والمعلومات المحدثة وتنزيل الموارد ذات الصلة لممارستك",
    "Application.Button": "زيارة الموقع",

    "Countries.Title": "البلدان التي تعمل بها دار الليانر",
    "Countries.Algeria": "الجزائر",
    "Countries.Belgium": "بلجيكا",
    "Countries.Sudan": "السودان",
    "Countries.Bahrain": "البحرين",
    "Countries.GreatBritain": "إنجلترا",
    "Countries.SaudiArabia": "السعودية",
    "Countries.Libya": "ليبيا",
    "Countries.France": "فرنسا",
    "Countries.Senegal": "السنغال",
    "Countries.Egypt": "مصر",
    "Countries.Pakistan": "باكستان",
    "Countries.UAE": "الإمارات",

    "SaFa.Caption": "تطبيق مجاني",
    "SaFa.Title": "دار صفا",
    "SaFa.Desc": "تحميل تطبيق \"DAR SaFa\" – التحكم في مرض السكري في رمضان",
    "CopyRight":
        "\nلقد أصبح إعداد هذا الموقع ممكنا من خلال منحة تعليمية غير مقيدة مقدمة من دول الخليج في سانوفي" +
        "© 2020 DAR alliance. All rights reserved",

    "Guidelines.Title": "الإرشادات",
    "Events.Title": "الأحداث",
    "Events.MoreInfo": "مزيد من المعلومات",
    "Events.Date": "تاريخ الفعالية"
}